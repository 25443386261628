import React from 'react';
import BrandAside from './BrandAside';
import Experiences from './Experiences';
import styles from './home.module.scss';

function Home({ dateRangeLowerLimit, experiences }) {
  return (
    <>
      <BrandAside experiencesCount={experiences?.length} />
      <Experiences
        experiences={experiences}
        dateRangeLowerLimit={dateRangeLowerLimit}
      />
    </>
  );
}

export default Home;
