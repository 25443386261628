import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/SEO';
import ToastContainer from '../components/ToastContainer';
import Home from '../features/Home';
import get from 'lodash/get';

function RootIndex({ data, location, pageContext, ...rest }) {
  const experiences = data?.allSanityExperienceInstance?.edges;

  return (
    <Layout location={location}>
      <SEO title="Home" />
      <Home
        dateRangeLowerLimit={pageContext.dateRangeLowerLimit}
        experiences={experiences}
      />
      <ToastContainer />
    </Layout>
  );
}

export const query = graphql`
  query HomePageQuery($dateRangeLowerLimit: Date, $dateRangeUpperLimit: Date) {
    allSanityExperienceInstance(
      filter: {
        activeDate: {
          local: { gte: $dateRangeLowerLimit, lte: $dateRangeUpperLimit }
        }
      }
      sort: { fields: [activeDate___local], order: ASC }
    ) {
      edges {
        node {
          activeDate {
            local
          }
          experience {
            category {
              name
            }
            costCategory {
              category
            }
            image {
              asset {
                fluid(maxWidth: 1000, maxHeight: 544) {
                  ...GatsbySanityImageFluid
                }
              }
            }
            partOfTown {
              name
            }
            slug {
              current
            }
            enhancedStops {
              duration
              place {
                title
              }
              event {
                place {
                  title
                }
              }
            }
            tags {
              name
            }
            title
          }
        }
      }
    }
  }
`;

export default RootIndex;
