import React, { useMemo } from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import cx from 'classnames';
import { DateTime } from 'luxon';
import XupFooter from '../../../components/XupFooter';
import ExperienceInfoItem from '../../../components/ExperienceInfoItem';
import useExperienceDuration from '../../../hooks/useExperienceDuration';
import useWindowWidth from '../../../hooks/useWindowWidth';
import { formatDateStringRelativeToToday } from '../../../utils/dateTimeFormatting';
import NoExperiencesGraphic from '../../../svg/NoExperiencesGraphic';
import styles from './homeExperiences.module.scss';

const yearMonthDayFormat = 'yyyy-MM-dd';

function sortExperiencesByDay(dateRangeLowerLimit, experiences) {
  const now = DateTime.fromISO(dateRangeLowerLimit);

  const nextSevenDaysMap = {};
  for (let i = 0; i < 8; i++) {
    nextSevenDaysMap[now.plus({ days: i }).toFormat(yearMonthDayFormat)] = [];
  }
  experiences.forEach(({ node }) => {
    const { activeDate } = node;

    const experienceDate = activeDate.local.slice(0, 10);
    Array.isArray(nextSevenDaysMap[experienceDate]) &&
      nextSevenDaysMap[experienceDate].push(node);
  });

  return nextSevenDaysMap;
}

function Experiences({ dateRangeLowerLimit, experiences }) {
  let width = useWindowWidth();

  if (experiences.length === 0) {
    return (
      <main className={cx(styles.main)}>
        <NoExperiencesGraphic className={styles.noExperiencesGraphic} />
      </main>
    );
  }
  const experiencesByDay = sortExperiencesByDay(
    dateRangeLowerLimit,
    experiences
  );

  return (
    <main className={styles.main}>
      <ul className={styles.experiencesContainer}>
        {Object.entries(experiencesByDay).map(([day, experiencesByDay]) => {
          return experiencesByDay.length > 0 ? (
            <>
              <h2 key={day} className={styles.dateTitle}>
                {formatDateStringRelativeToToday(dateRangeLowerLimit, day)}
              </h2>
              {experiencesByDay.map((experienceNode, index) => (
                <li key={experienceNode?.experience.slug.current + index}>
                  <ExperiencePreview
                    experience={experienceNode.experience}
                    slug={`/xperiences/${day}/${experienceNode.experience.slug.current}`}
                  />
                </li>
              ))}
            </>
          ) : null;
        })}
      </ul>
      {/* <div className={styles.updateMessage}>Updated every Sunday 😍</div>
      <div className={styles.updateDivider} /> */}
      {width < 1337 && (
        <>
          <XupFooter />
        </>
      )}
    </main>
  );
}

function ExperiencePreview({ experience, slug }) {
  const durationMessage = useExperienceDuration(
    experience.enhancedStops,
    'experience'
  );

  return (
    <Link to={slug} className={styles.experienceLink}>
      <div className={styles.experience}>
        <Img
          alt="experience"
          fluid={experience.image.asset.fluid}
          className={styles.experienceImage}
        />
        <div className={styles.experienceOverview}>
          <p className={styles.experienceCategory}>
            {experience.category.name}
          </p>
          <h3 className={styles.experienceTitle}> {experience.title}</h3>
          <div className={styles.experienceInfo}>
            <div className={styles.experienceInfoRow}>
              <ExperienceInfoItem type="duration" value={durationMessage} />
              <ExperienceInfoItem
                type="itinerary"
                value={`${experience.enhancedStops.length} ${
                  experience.enhancedStops.length === 1 ? 'stop' : 'stops'
                }`}
              />
            </div>
            <div className={styles.experienceInfoRow}>
              <ExperienceInfoItem
                type="area"
                value={experience.partOfTown.name}
              />
              <ExperienceInfoItem
                type="cost"
                value={experience.costCategory.category}
              />
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default Experiences;
