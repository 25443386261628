import React from 'react';
import { wrapper } from './socialIcon.module.scss';

function SocialIcon({ children, link, ...rest }) {
  return (
    <a target="_blank" rel="nofollow noopener" href={link} className={wrapper}>
      {children}
    </a>
  );
}

export default SocialIcon;
