import React from 'react';
import cx from 'classnames';
import useScrollPosition from '../../../hooks/useScrollPosition';
import useWindowWidth from '../../../hooks/useWindowWidth';
import FeedbackButton from '../../../components/FeedbackButton';
// import Tooltip from '../../../components/Tooltip'
import XupFooter from '../../../components/XupFooter';
import FacebookIcon from '../../../svg/FacebookIcon';
import InstagramIcon from '../../../svg/InstagramIcon';
//import InfoIcon from '../../../svg/InfoIcon'
import SocialIcon from '../../../components/SocialIcon';
import XupWordmark from '../../../svg/XupBetaWordmark';
import styles from './brandAside.module.scss';

function BrandAside({ experiencesCount }) {
  let width = useWindowWidth();

  let position = useScrollPosition();
  let collapsed = position.y > 0;

  return (
    <aside className={cx(styles.aside, { [styles.collapsed]: collapsed })}>
      <div className={styles.container}>
        <div className={styles.title}>
          <section>
            <XupWordmark className={styles.logo} />
          </section>

          <section className={styles.titleText}>
            <h1 className={cx(styles.h1, 'hero')}>
              {`${experiencesCount} ${
                experiencesCount !== 1 ? 'Xperiences' : 'Xperience'
              }`}
            </h1>
            <p className={styles.p}>
              this week{' '}
              {/* <span className={styles.tooltipIcon} data-for="info" data-tip="">
                <InfoIcon />
              </span> */}
              {/* <Tooltip
                effect="solid"
                place="right"
                id="info"
                label="Notifications"
              >
                Every Sunday night, we’ll add the Xperiences for the following
                week.
              </Tooltip> */}
            </p>
          </section>
        </div>
        <div className={styles.actions}>
          <section className={styles.socialIcons}>
            <SocialIcon link="https://www.facebook.com/whatsxup/">
              <FacebookIcon />
            </SocialIcon>
            <SocialIcon link="https://www.instagram.com/xupapp/">
              <InstagramIcon />
            </SocialIcon>
          </section>
          <section className={styles.feebackContainer}>
            <FeedbackButton />
          </section>
        </div>
      </div>
      {width >= 1337 && <XupFooter />}
    </aside>
  );
}

export default BrandAside;
